<template>
  <div> 
    <b-row class="m-0  justify-content-center">
      <b-col
        class="content-header"
        cols="12"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
      </b-col>
    </b-row>
  
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    
  },
}
</script>

<style>

</style>
